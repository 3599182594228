<template>
  <div class="question-result-target-score">
    <div class="questionnaire-head">
      <div class="head-left">
        <el-image class="head-icon" :src="require(`../../../assets/image/recommend.png`)" fit="contain"></el-image>
        <div class="head-title">目标完成进度-数字积分</div>
      </div>
      <el-image class="head-right" @click="sendExit" :src="require(`../../../assets/image/exit2.png`)" fit="contain"></el-image>
    </div>
    <div class="result-target-body">
      <div class="body-echarts">
        <div class="echarts-title">
          <div class="title-left">
            <div>
              <div>总计</div>
              <div><span class="number">{{ subhead * 10 }}</span>分</div>
            </div>
            <div>
              <div>目标<span class="number">{{ this.targetData.integralAll.length * 10 }}</span>分</div>
              <div>进度<span class="number">{{ this.targetData.integralAll.length > 0 ? Math.floor((subhead / this.targetData.integralAll.length) * 100) : 0 }}%</span></div>
            </div>
          </div>
          <div class="title-right">
            <div class="right-label">
              <div class="label" :class="[filter === 1 ? 'opt-label' : '']" @click="getTargetData(1)">周</div>
              <div class="label" :class="[filter === 2 ? 'opt-label' : '']" @click="getTargetData(2)">月</div>
              <div class="label" :class="[filter === 3 ? 'opt-label' : '']" @click="getTargetData(3)">年</div>
            </div>
            <div class="right-annotation">{{ this.targetData.annotation }}</div>
          </div>
        </div>
        <lineChart :cdata="targetData.cdata" />
      </div>
      <div class="body-integral">
        <div class="integral-title">完成目标赚积分</div>
        <div class="integral-main">
          <div class="main-item" :class="[item.is_target === 0 ? 'main-item-false' : 'main-item-true']" v-for="item in targetData.integralAll" :key="item.student_result_target_id">
            <div class="item-title">{{ item.project }}</div>
            <div class="item-main" @click="updateTarget(item)" v-bind:class="[item.is_target === 0 ? 'item-main-false' : 'item-main-true']">+10积分</div>
          </div>
        </div>
      </div>
      <div class="body-medal">
        <div class="medal-title">
          <div>成就勋章</div>
          <div class="subhead">{{ `（${subhead}/${this.targetData.integralAll.length}）` }}</div>
        </div>
        <div class="medal-main">
          <div class="main-data" v-for="item in targetData.integralAll" :key="item.student_result_target_id">
            <el-image class="img" v-if="item.is_target === 1" :src="require(`../../../assets/image/badge01.png`)" fit="contain"></el-image>
            <el-image class="img" v-else :src="require(`../../../assets/image/badge02.png`)" fit="contain"></el-image>
            <span class="title">{{ item.is_target === 1 ? item.project : '未获得' }}</span>
            <span class="date">{{ item.is_target === 1 ? bearTime(item.bear_time) : '未获得' }}</span>
          </div>
        </div>
      </div>
      <div class="body-button">
        <el-button class="produce-button" @click="sendMessage">编辑目标</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import lineChart from '../components/lineChart.vue'
import { getTargetIntegral, updateIsTarget } from '../../../request/api'

export default {
  components: {
    lineChart
  },
  data() {
    return {
      questionnaire_result_id: undefined,
      filter: 1,
      targetData: {
        annotation: '',
        cdata: {
          end_time: [],
          is_target: []
        },
        integralAll: []
      }
    }
  },
  computed: {
    subhead() {
      return this.targetData.integralAll.filter(obj => obj.is_target === 1).length
    }
  },
  methods: {
    sendExit () {
      this.$emit('update-value')
    },
    bearTime(bear_time) {
      const date = new Date(bear_time)
      if (isNaN(date.getTime())) {
        return this.$message.error('日期格式无效，联系管理员解决');
      }

      return `${date.getFullYear()}年${('0' + (date.getMonth() + 1)).slice(-2)}月${('0' + date.getDate()).slice(-2)}日获得`
    },
    sendMessage() {
      this.$confirm('当前积分会被重置，确认修改吗？', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$router.push(
          {
            path: '/questionResultTarget',
            query: {
              questionnaire_result_id: this.questionnaire_result_id
            }
          }
        )
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消编辑",
        });
      })
    },
    getTargetData(filter_id) {
      this.filter = filter_id
      this.init()
    },
    async updateTarget(item) {
      try {
        const res = await updateIsTarget({ student_result_target_id: item.student_result_target_id })
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
        }

        this.$message.success("修改成功");
        this.init()
      } catch(err) {
        this.$message.error(err)
      }
    },
    async init() {
      if (this.questionnaire_result_id === undefined) return

      try {
        const res = await getTargetIntegral(this.questionnaire_result_id, this.filter)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
        }
        const targetData = res.data.data
        this.targetData.cdata.end_time = targetData.end_time
        this.targetData.cdata.is_target = targetData.is_target
        this.targetData.integralAll = targetData.resultTargets

        const begin_annotation = new Date(targetData.begin_annotation)
        const finish_annotation = new Date(targetData.finish_annotation)

        this.targetData.annotation = (`${begin_annotation.getFullYear()}年${('0' + (begin_annotation.getMonth() + 1)).slice(-2)}月${('0' + begin_annotation.getDate()).slice(-2)}日-${finish_annotation.getFullYear()}年${('0' + (finish_annotation.getMonth() + 1)).slice(-2)}月${('0' + finish_annotation.getDate()).slice(-2)}日`)
      } catch(err) {
        this.$message.error('获取数据出错了', err)
      }
    }
  },
  created() {
    this.questionnaire_result_id = this.$route.query.questionnaire_result_id
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.question-result-target-score {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .result-target-body {
    background-color: #fff;
    height: 92%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    .body-echarts {
      display: flex;
      flex-direction: column;
      .echarts-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-left {
          display: flex;
          width: 150px;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          .number {
            font-size: 18px;
            color: #5699fe;
            font-weight: bold;
          }
        }
        .title-right {
          display: flex;
          flex-direction: column;
          width: 180px;
          .right-label {
            display: flex;
            border: 1px solid #696969;
            justify-content: space-between;
            height: 38px;
            text-align: center;
            line-height: 38px;
            border-radius: 4px;
            font-weight: bold;
            margin-bottom: 4px;
            .opt-label {
              background-color: #5699fe;
              color: #fff;
            }
            .label {
              flex: 1;
              cursor: pointer;
              border-right: 1px solid #696969;
              &:last-child {
                border-right: none;
              }
            }
          }
          .right-annotation {
            font-size: 10px;
            color: #7a7c7e;
            text-align: center;
          }
        }
      }
    }
    .body-integral {
      height: 20%;
      display: flex;
      flex-direction: column;
      .integral-title {
        height: 24%;
        font-size: 18px;
        font-weight: bold;
        color: #5699fe;
      }
      .integral-main {
        flex: 1;
        display: flex;
        overflow-x: auto;
        padding-bottom: 6px;
        .main-item:not(:last-child) {
          margin-right: 10px;
        }
        .main-item {
          min-width: 140px;
          display: flex;
          flex-direction: column;
          border-radius: 4px;
          .item-title {
            font-size: 18px;
            color: #5699fe;
            height: 66%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .item-main {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            cursor: pointer;
            font-size: 18px;
            font-weight: bold;
          }
          .item-main-true {
            border: 2px solid #c0c0c0;
            color: #9e9d9d;
          }
          .item-main-false {
            background-color: #ffcf34;
          }
        }
        .main-item-true {
          background-color: #f3f3f3;
        }
        .main-item-false {
          background-color: #e8f1ff;
        }
      }
    }
    .body-medal {
      flex: 1;
      display: flex;
      flex-direction: column;
      .medal-title {
        display: flex;
        height: 24%;
        font-size: 18px;
        font-weight: bold;
        color: #5699fe;
        .subhead {
          font-size: 12px;
          color: #c1c1c1;
          line-height: 24px;
        }
      }
      .medal-main {
        display: flex;
        overflow-x: auto;
        .main-data {
          display: flex;
          flex-direction: column;
          min-width: 120px;
          align-items: center;
          .title {
            font-size: 16px;
            font-weight: bold;
          }
          .date {
            font-size: 12px;
            color: #a8a8a8;
          }
        }
        .main-data:not(:last-child) {
          margin-right: 30px;
        }
        .img {
          width: 70px;
        }
      }
    }
    .body-button {
      display: flex;
      justify-content: flex-end;
      .produce-button {
        width: 120px;
        color: #fff;
        font-weight: bold;
        background-image: linear-gradient(to right, #7763ea, #569be8);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .1);
        border: none;
        &:hover {
          box-shadow: 0 6px 14px 0 rgba(0, 0, 0, .2);
        }
        &:active {
          box-shadow: inset 0 3px 4px rgba(0, 0, 0, .2);
        }
      }
    }
  }
  .questionnaire-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
}
</style>